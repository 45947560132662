import React, { Fragment, memo, useEffect, useState } from "react";
import { Row, Col, Container, Nav, Tab, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StarRatings from 'react-star-ratings';
import RelatedMovies from "../../Components/RelatedMovies";
import FsLightBox from "../../Components/fslight-box";
import { getShowDetails, getSimilarVideos, updateLike } from "./service";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import BreadCrumbWidget from "../../Components/BreadcrumbWidget";
import { FacebookShareButton, TwitterShareButton } from "react-share";

import Loading from "../../Components/Loading/Loading";
import MainLoading from "../../Components/Loading/MainLoading";
import { updateWatchlistData, getUserSubscription, authenticate } from "../../network/service";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import Translate from "../../Components/MultiLanguage/Translate";
import UpcomingMovies from "../../Components/sections/UpcomingMovies";
import { stripeSession } from "../Subscription/service";

const MovieDetail = memo(() => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const userSubscriptionData = useSelector((state) => state?.userSubscription?.value);
  const user = useSelector((state) => state?.user?.value);
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch();
  const [showId, setShowId] = useState(location?.state?.movie);
  const showUniqueId = showId
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  const imdb_rating = "4.8/10"
  const buttonColor = projectInfo?.projectConfig?.config?.BUTTON_COLOR;
  const buttonSelectionColor = projectInfo?.projectConfig?.config?.BUTTON_SELECTION_COLOR;
  const [loading, setLoading] = useState(true);
  const [showDetails, setShowDetails] = useState({});
  const [similarVideos, setSimilarVideos] = useState([]);
  const [watchlistStatus, setWatchlistStatus] = useState();
  const [favouriteStatus, setFavouriteStatus] = useState()
  const [isFreeVideo, setIsFreeVideo] = useState();
  const [selectedVideoDetails, setSelectedVideoDetails] = useState();
  const [initialVideoSubscriptions, setInitialVideoSubscriptions] = useState([]);
  const [isSubscribedUser, setIsSubscribedUser] = useState();
  const [subscribeButton, setSubscribeButton] = useState();
  const [logoutModal, setLogoutModal] = useState(false);
  const [modalType, setModalType] = useState({ type: "logoutAll" });
  const [selectedSeasonVideos, setSelectedSeasonVideos] = useState([])
  const [pageURL, setPageURL] = useState(0)

  useEffect(() => {
    window.scroll(0, 0);
    let vanityUrl = location?.pathname.split("/")[2];
    if (vanityUrl !== "null" && vanityUrl !== "undefined" && vanityUrl) {
      fetchShowDetails(vanityUrl);
    } else {
      fetchShowDetails(showId);
    }

  }, [location?.pathname])

  useEffect(() => {
    localStorage.removeItem("couponId");
  }, [])

  useEffect(() => {
    setPageURL(window.location.href);
  }, []);

  useEffect(() => {
    if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
      if (showDetails?.single_video === 1) {
        setSelectedVideoDetails(showDetails?.videos && showDetails?.videos[0]);
        setInitialVideoSubscriptions(showDetails?.videos && showDetails?.videos[0]?.subscriptions);

        if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
          if (
            (showDetails?.videos && showDetails?.videos[0]?.subscriptions?.length > 0) ||
            (showDetails?.subscriptions && showDetails?.subscriptions?.length > 0)
          ) {
            let isSubscribedUser;
            if (showDetails?.subscriptions?.length > 0) {
              isSubscribedUser =
                userSubscriptionData?.data?.length > 0
                  ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                  : false;
            } else {
              isSubscribedUser =
                userSubscriptionData?.data?.length > 0
                  ? subscriptionCheck(
                    userSubscriptionData?.data,
                    showDetails?.videos && showDetails?.videos[0]?.subscriptions
                  )
                  : false;
            }

            if (isSubscribedUser === true) {
              setIsSubscribedUser(true);
              setSubscribeButton(false);
            } else {
              setIsSubscribedUser(false);

              if (showDetails?.videos[0]?.free_video === true) {
                // for inital free video checking for showing button name "subscribe" ,"play Now"
                setSubscribeButton(false);

                setIsFreeVideo(true);
              } else {
                setSubscribeButton(true);

                setIsFreeVideo(false);
              }
            }
          } else {
            setIsSubscribedUser(true);
            setSubscribeButton(false);
          }
        } else {
          setIsSubscribedUser(true);
          setSubscribeButton(false);
        }
      } else {
        if (showDetails?.videos && showDetails?.videos[0]?.videos && showDetails?.videos[0]?.videos[0]) {
          setInitialVideoSubscriptions(showDetails?.videos[0]?.videos[0]?.subscriptions);

          let video = showDetails?.videos[0]?.videos[0];
          setSelectedVideoDetails(showDetails?.videos[0]?.videos[0]);
          if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
            if (video?.subscriptions?.length > 0 || showDetails?.subscriptions?.length > 0) {
              let isSubscribedUser;
              if (showDetails?.subsriptions?.length > 0) {
                isSubscribedUser =
                  userSubscriptionData?.data?.length > 0
                    ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                    : false;
              } else {
                isSubscribedUser =
                  userSubscriptionData?.data?.length > 0
                    ? subscriptionCheck(userSubscriptionData?.data, video?.subscriptions)
                    : false;
              }

              if (isSubscribedUser === true) {
                setIsSubscribedUser(true);
                setSubscribeButton(false);
              } else {
                setIsSubscribedUser(false);

                if (video?.free_video === true) {
                  // for inital free video checking for showing button name "subscribe" ,"play Now"
                  setSubscribeButton(false);

                  setIsFreeVideo(true);
                } else {
                  setSubscribeButton(true);

                  setIsFreeVideo(false);
                }
              }
            } else {
              setIsSubscribedUser(true);
              setSubscribeButton(false);
            }
          } else {
            setIsSubscribedUser(true);
            setSubscribeButton(false);
          }
        } else {
          setInitialVideoSubscriptions(showDetails?.videos && showDetails?.videos[0]?.subscriptions);
          let video = showDetails?.videos && showDetails?.videos[0];
          setSelectedVideoDetails(showDetails?.videos && showDetails?.videos[0]);
          if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
            if (video?.subscriptions?.length > 0 || showDetails?.subscriptions?.length > 0) {
              let isSubscribedUser;
              if (showDetails?.subscriptions?.length > 0) {
                isSubscribedUser =
                  userSubscriptionData?.data?.length > 0
                    ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                    : false;
              } else {
                isSubscribedUser =
                  userSubscriptionData?.data?.length > 0
                    ? subscriptionCheck(userSubscriptionData?.data, video?.subscriptions)
                    : false;
              }

              if (isSubscribedUser === true) {
                setIsSubscribedUser(true);
                setSubscribeButton(false);
              } else {
                setIsSubscribedUser(false);

                if (video?.free_video === true) {
                  // for inital free video checking for showing button name "subscribe" ,"play Now"
                  setSubscribeButton(false);

                  setIsFreeVideo(true);
                } else {
                  setSubscribeButton(true);

                  setIsFreeVideo(false);
                }
              }
            } else {
              setIsSubscribedUser(true);
              setSubscribeButton(false);
            }
          } else {
            setIsSubscribedUser(true);
            setSubscribeButton(false);
          }
        }
      }
    } else if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "false") {
      setSubscribeButton(false);
      if (userSubscriptionData?.login_needed === false) {
        setIsFreeVideo(true);
      } else {
        setIsFreeVideo(false);
      }
      if (showDetails?.single_video === 1) {
        setInitialVideoSubscriptions(showDetails?.videos[0]?.subscriptions);
        setSelectedVideoDetails(showDetails?.videos[0]);
      } else {
        if (showDetails?.videos && showDetails?.videos[0]?.videos) {
          setInitialVideoSubscriptions(showDetails?.videos && showDetails?.videos[0]?.videos[0]?.subscriptions);
          setSelectedVideoDetails(showDetails?.videos && showDetails?.videos[0]?.videos[0]);
        } else {
          setInitialVideoSubscriptions(showDetails?.videos && showDetails?.videos[0]?.subscriptions);
          setSelectedVideoDetails(showDetails?.videos && showDetails?.videos[0]);
        }
      }
    }
  }, [showDetails])

  // useEnterExit();

  // const playerRef = React.useRef(null);

  // const videoJsOptions = {
  //   autoplay: false,
  //   controls: true,
  //   responsive: true,
  //   techOrder: ["youtube"],
  //   sources: [
  //     {
  //       src: "https://www.youtube.com/watch?v=QCGq1epI9pQ",
  //       type: "video/youtube",
  //     },
  //   ],
  //   youtube: { iv_load_policy: 1 },
  // };

  const fetchShowDetails = async (sId) => {
    try {
      setLoading(true);
      const showDetailsResponse = await getShowDetails(appInfo, sId);
      if (showDetailsResponse?.status === 200) {
        setShowDetails(showDetailsResponse?.data?.data);
        setWatchlistStatus(showDetailsResponse?.data?.data?.watchlist_flag)
        setSelectedSeasonVideos(showDetailsResponse?.data?.data?.videos)
        if (showDetailsResponse?.data?.data?.liked_flag === 1) {
          setFavouriteStatus("liked")
        } else {
          setFavouriteStatus("disliked")
        }
        fetchSimilarVideos(showDetailsResponse?.data?.data?.show_id);
        setLoading(false);

      }
    } catch (err) {
      setLoading(false);
    }
  };
  const fetchSimilarVideos = async (id) => {
    const similarVideosResponse = await getSimilarVideos(appInfo, id);
    if (similarVideosResponse?.status === 200) {
      setSimilarVideos(similarVideosResponse?.data?.data);
    }
  };

  // const handlePlayerReady = (player) => {
  //   playerRef.current = player;

  //   // You can handle player events here, for example:
  //   player.on("waiting", () => {
  //     videojs.log("player is waiting");
  //   });

  //   player.on("dispose", () => {
  //     videojs.log("player will dispose");
  //   });
  // };
  const addToFavourites = () => {
    if (user) {
      updateFavourites(1);
    } else {
      navigate("/login", { state: { ...location?.state, path: location?.pathname } });
    }
  };
  const removeFromFavourites = () => {
    updateFavourites(0);
  };

  const updateFavourites = async (flag) => {
    const response = await updateLike(appInfo, showDetails?.show_id, flag);
    if (response?.status === 200) {
      if (flag === 1) {
        setFavouriteStatus("liked");
      } else {
        setFavouriteStatus("disliked");
      }
    }
  };

  const updateWatchlist = async (flag) => {
    try {
      const response = await updateWatchlistData(appInfo, showDetails?.show_id, flag);

      if (response?.status === 200) {
        if (flag === 1) {
          toast.success("Added to mylist", {
            position: "bottom-center",
            toastId: "success1",
          });
          setWatchlistStatus(1);
        } else {
          toast.success("Removed from mylist", {
            position: "bottom-center",
          });
          setWatchlistStatus(0);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const addToWatchlist = () => {
    if (user) {
      updateWatchlist(1);
    } else {
      navigate("/login", { state: { ...location?.state, path: location?.pathname } });
    }
  };
  const removeFromWatchlist = () => {
    updateWatchlist(0);
  };

  const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub?.some((user) => videoSub?.some((video) => user?.sub_id === video?.subscription_id));
    return hasMatch;
  };

  const playVideo = async (videoDetails, watchDuration) => {
    localStorage.setItem("vanityUrl", showDetails?.vanity_url);
    navigate(`/videos/${videoDetails.vanity_url}`, {
      state: {
        isSubscribedUser,
        videoDetails,
        showDetails,
        prevLocation: location?.search ? location?.pathname + location?.search : location?.pathname,
        notSubCheck: true,
      },
    });

  }

  const handleSubscribe = (data) => {

    localStorage.setItem("selectedSubId", data?.subscription_id);
    localStorage.setItem("selectedAmount", data?.price);
    localStorage.setItem("showId", showDetails?.show_id);
    localStorage.setItem("vanityUrl", showDetails?.vanity_url);

    if (user) {

      if (projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true') {
        proceedToPayment(data?.subscription_id);
      } else {
        navigate("/payment", { state: { subscription: { ...data, is_coupon: false } } });
      }

    } else {

      if (projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true') {
        navigate("/checkout", { state: { showId: showDetails?.show_id, subscriptionId: data?.subscription_id, subscriptionPrice: data?.price } });
      } else {
        navigate("/login", {
          state: { path: "/show-details/" + showDetails?.show_id, showId: showDetails?.show_id, ...location?.state },
        });
      }

    }
  };

  const proceedToPayment = async (selectedPlan) => {
    const paymentInfo = {
      subId: selectedPlan,
    };
    try {
      const response = await stripeSession(appInfo, paymentInfo);

      if (response?.status === 200) {
        window.open(response?.data?.url, "_self")
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  }



  const tokenAuthenticate = async () => {
    const response = await authenticate(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
    }
  };



  const watchVideoHandler = async (videoDetails, directSubscribe, directSubscribeData) => {
    setSelectedVideoDetails({ ...videoDetails, showId: showUniqueId });
    playVideo(videoDetails);
  };


  if (loading) {
    return <MainLoading />;
  }

  return (
    <Fragment>

      <div
        className="screenBanner iq-breadcrumb d-flex align-items-center justify-content-center"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.9)), url(${showDetails?.logo_thumb})`
        }
        }
      >

        {/* <i
          className="fa-solid fa-play rounded-circle text-center bg-white p-3"
          onClick={() => {
            navigate("/videoplayer", {
              state: {
                videoDetails: { video_id: showDetails?.videos[0]?.video_id || showDetails?.videos[0]?.videos[0]?.video_id }
              }
            });
          }}

        ></i> */}

      </div>
      <div className="details-part">
        <Container fluid>
          <Row>
            <Col lg="12">
              <div className="trending-info mt-4 pt-0 pb-4">
                <Row>
                  <Col md="9" className="mb-auto">
                    <div className="d-block d-lg-flex align-items-center" style={{ marginBottom: "10px" }}>
                      <h2 className="trending-text fw-bold texture-text text-uppercase my-0 fadeInLeft animated d-inline-block">
                        {showDetails?.show_name}
                      </h2>
                      {/* {showDetails?.imdb_rating && <div className="slider-ratting d-flex align-items-center ms-lg-3 ms-0">
                        <RatingStar
                                              count="4"
                                              count1="1"
                                              starColor="text-warning"
                                            />
                        <span className="text-white ms-2">
                          {showDetails?.imdb_rating} (imdb)
                        </span>
                      </div>
                      } */}
                    </div>
                    {subscribeButton ? (
                      // <button className="subscribe  px-5 py-1 my-2 rounded text-white" style={{ background: "red" }} onClick={() => watchVideoHandler(selectedVideoDetails)}>
                      //   <span className="text-uppercase button-text"><Translate textKey={'subscribe'} /></span>
                      // </button>
                      <div className="iq-button ">
                        <div
                          onClick={() => watchVideoHandler(selectedVideoDetails)}
                          className="btn text-uppercase position-relative px-4 py-3"
                        >
                          <span className="button-text"><Translate textKey={'watch_now'} /></span>
                          <i className="fa-solid fa-play"></i>
                        </div>
                      </div>
                    ) : (
                      // <button className="play d-flex align-items-center justify-content-center px-4 py-2 rounded my-2" onClick={() => watchVideoHandler(selectedVideoDetails)}>

                      //   <span className="text-black text-uppercase fw-bold px-2" ><Translate textKey={'play'} /></span>
                      //   <div className="icon">
                      //     <i className="fa-solid fa-play"> </i>
                      //   </div>
                      // </button>
                      <div className="iq-button " >
                        <div
                          onClick={() => watchVideoHandler(selectedVideoDetails)}
                          className="btn text-uppercase position-relative px-4 py-3"
                        >
                          <span className="button-text"><Translate textKey={'play_now'} /></span>
                          <i className="fa-solid fa-play"></i>
                        </div>
                      </div>
                    )}

                    <ul className="p-0 mt-2 list-inline d-flex flex-wrap movie-tag">
                      {showDetails?.categories?.length > 0 && showDetails?.categories?.map((item, index) => {
                        return (
                          <li key={index} className="trending-list">
                            <Link
                              to={`/category/${item?.key}?career=${item?.key}`}
                              className="text-primary text-capitalize"
                            >
                              {item?.category_name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                    <div className="d-flex flex-wrap align-items-center text-white text-detail flex-wrap mb-4">
                      {showDetails?.categories?.length > 0 && <span className="badge bg-secondary">{showDetails?.categories[0]?.category_name}</span>}
                      {showDetails?.duration_text && <span className="ms-3 font-Weight-500 genres-info me-2">
                        {showDetails?.duration_text}{" "}
                      </span>}
                      {showDetails?.year && <span className="trending-year trending-year-list font-Weight-500 genres-info mx-2">
                        {showDetails?.year}
                      </span>}

                    </div>

                    <div>
                      {!isSubscribedUser ?
                        (showDetails?.subscriptions?.length > 0 || selectedVideoDetails?.free_video === true ? (
                          <div className="detail-subscription-container">
                            {showDetails?.subscriptions?.length > 0 &&
                              showDetails?.subscriptions?.map((item, index) => {
                                return <div className="package-item" onClick={() => handleSubscribe(item)}>
                                  <div className="heading-section">
                                    <div className="heading-wrapper">
                                      <span className="heading">{item?.subscription_name}</span>
                                      <span className="sub-heading">{item?.subscription_text}</span>
                                    </div>
                                  </div>
                                </div>
                              })}
                            {showDetails?.single_video === 1 && selectedVideoDetails?.free_video === true && (
                              <button className=" px-2 mx-1 rounded bg-success text-white " onClick={() => watchVideoHandler(selectedVideoDetails)}>
                                <span>
                                  <Translate textKey={'free'} />
                                </span>
                              </button>
                            )}
                          </div>
                        ) : null) : null

                      }
                    </div>
                    <div className="d-flex ">
                      <div className="d-flex align-items-center gap-4 flex-wrap">
                        <ul className="list-inline p-0 share-icons music-play-lists mb-n2 mx-n2">
                          {<li className="share">
                            <span>
                              <i className="fa-solid fa-share-nodes"></i>
                            </span>
                            <div className="share-box">
                              <svg
                                width="15"
                                height="40"
                                viewBox="0 0 15 40"
                                className="share-shape"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M14.8842 40C6.82983 37.2868 1 29.3582 1 20C1 10.6418 6.82983 2.71323 14.8842 0H0V40H14.8842Z"
                                  fill="#191919"
                                ></path>
                              </svg>
                              <div className="d-flex align-items-center">
                                <Link>
                                  <FacebookShareButton url={pageURL} quote={"Share"} className="facebook">
                                    <i className="fa-brands fa-facebook-f text-white"></i>
                                  </FacebookShareButton>
                                </Link>
                                <Link>
                                  <TwitterShareButton url={pageURL} className="twitter">
                                    <i className="fa-brands fa-twitter text-white"></i>
                                  </TwitterShareButton>
                                </Link>
                              </div>
                            </div>
                          </li>}
                          <li>
                            {favouriteStatus === "liked" ? <span title="Dislike Show" onClick={removeFromFavourites}>
                              <i className="fa-solid fa-heart text-danger"></i>
                            </span> : <span title="Like Show" onClick={addToFavourites}>
                              <i className="fa-solid fa-heart text-dark" ></i>
                            </span>}
                          </li>
                          <li>
                            {watchlistStatus === 1 ? <span title="Remove from My List" onClick={() => (removeFromWatchlist())}>
                              <i className="fa-solid fa-check" ></i>
                            </span> : <span title="Add to My List" onClick={() => (addToWatchlist())}>
                              <i className="fa-solid fa-plus" ></i>
                            </span>}
                          </li>
                          <ToastContainer />
                          {/* <li>
                          <span>
                            <i className="fa-solid fa-download"></i>
                          </span>
                        </li> */}

                        </ul>


                        {/* <div className="movie-detail-select">
                        <Form.Select
                          name="movieselect"
                          className="form-select "
                        >
                          <option value="1">Playlist</option>
                          <option value="2">Zombie Island</option>
                          <option value="3">Sand Dust</option>
                          <option value="4">Jumbo Queen</option>
                        </Form.Select>
                      </div> */}
                      </div>

                      {showDetails?.imdb_rating && <div className="p-2">
                        <div className="d-flex align-items-center gap-3">
                          <StarRatings
                            rating={parseFloat(showDetails?.imdb_rating) / 2}
                            starRatedColor="gold"
                            numberOfStars={5}
                            name='rating'
                            starDimension="25px"
                            starSpacing="5px"
                          />
                          <span>{showDetails?.imdb_rating}{' '}(IMDb)</span>
                        </div>
                      </div>}
                    </div>

                    <ul className="iq-blogtag list-unstyled d-flex flex-wrap align-items-center gap-3 p-0">
                      {showDetails?.video_tags?.length > 0 && <li className="iq-tag-title text-primary mb-0">
                        <i className="fa fa-tags " aria-hidden="true"></i>Tags:{" "}
                      </li>}
                      {showDetails?.video_tags?.length > 0 && showDetails?.video_tags?.map((item, index) => {
                        return (
                          <li key={index}>
                            <span className="text-white">
                              {item}
                            </span>
                            <span className="text-white">,</span>
                          </li>
                        );
                      })}
                    </ul>
                    <ul className="iq-blogtag list-unstyled d-flex flex-wrap align-items-center gap-3 p-0">

                    </ul>
                  </Col>
                  {showDetails?.teaser && <FsLightBox image={showDetails?.logo_thumb} trailerLink={showDetails?.teaser} appInfo={appInfo} />}
                </Row>
              </div>
              <div className="content-details trending-info">
                <Tab.Container defaultActiveKey="first">
                  <Nav className="iq-custom-tab tab-bg-gredient-center d-flex nav nav-pills align-items-center text-center mb-5  list-inline">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="first"
                        variant=" d-flex align-items-center"
                        id="nav-description-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-description"
                        type="button"
                        role="tab"
                        aria-controls="nav-description"
                        aria-selected="true"
                      >
                        <Translate textKey={'description'} />
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link
                        eventKey="second"
                        variant=""
                        id="nav-review-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-review"
                        type="button"
                        role="tab"
                        aria-controls="nav-review"
                        aria-selected="false"
                      >
                        Rate & Review
                      </Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item>
                      <Nav.Link
                        eventKey="third"
                        variant=""
                        id="nav-sources-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-sources"
                        type="button"
                        role="tab"
                        aria-controls="nav-sources"
                        aria-selected="false"
                      >
                        Sources
                      </Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane
                      className=" fade show"
                      eventKey="first"
                      id="nav-description"
                      role="tabpanel"
                      aria-labelledby="nav-description-tab"
                    >
                      <p className="text-white">{showDetails?.synopsis}</p>
                    </Tab.Pane>
                    <Tab.Pane
                      className=" fade"
                      id="nav-review"
                      eventKey="second"
                      role="tabpanel"
                      aria-labelledby="nav-review-tab"
                    >
                      {/* <ReviewComponent /> */}
                    </Tab.Pane>
                    <Tab.Pane
                      className=" fade"
                      id="nav-sources"
                      eventKey="third"
                      role="tabpanel"
                      aria-labelledby="nav-sources-tab"
                    >
                      {/* <Sources /> */}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="cast-tabs">
        <Container fluid>
          {(showDetails?.cast?.length > 0 || showDetails?.show_cast !== null || showDetails?.crew?.length > 0 || showDetails?.director !== null || showDetails?.producer !== null || showDetails?.writer !== null) && <div className="content-details trending-info g-border iq-rtl-direction">
            <Tab.Container defaultActiveKey="first">
              <Nav className="iq-custom-tab tab-bg-fill d-flex nav nav-pills mb-5 ">
                {(showDetails?.cast?.length > 0 || showDetails?.show_cast !== null) && <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    variant=" d-flex align-items-center"
                    id="nav-cast-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-cast"
                    type="button"
                    role="tab"
                    aria-controls="nav-cast"
                    aria-selected="true"
                  >
                    <Translate textKey={'cast'} />
                  </Nav.Link>
                </Nav.Item>}
                {(showDetails?.crew?.length > 0 || showDetails?.director !== null || showDetails?.producer !== null || showDetails?.writer !== null) && <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    variant=""
                    id="nav-crew-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-crew"
                    type="button"
                    role="tab"
                    aria-controls="nav-crew"
                    aria-selected="false"
                  >
                    <Translate textKey={'crew'} />
                  </Nav.Link>
                </Nav.Item>}
              </Nav>
              <Tab.Content>
                <Tab.Pane
                  className=" fade show"
                  eventKey="first"
                  id="nav-cast"
                  role="tabpanel"
                  aria-labelledby="nav-cast-tab"
                >
                  <Swiper
                    slidesPerView={5}
                    loop={false}
                    modules={[Navigation]}
                    tag="ul"
                    className="position-relative swiper-card list-inline"
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                      },
                      576: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                      },
                      1025: {
                        slidesPerView: 5,
                        spaceBetween: 0,
                      },
                      1500: {
                        slidesPerView: 5,
                        spaceBetween: 0,
                      },
                    }}
                  >
                    {showDetails?.cast?.length > 0 ? showDetails?.cast?.map((item, index) => {
                      return (
                        <SwiperSlide key={index} as="li">
                          <Row className="cast-images m-0 align-items-center position-relative">
                            <Col className="col-4 img-box p-0">
                              {item.image && <img
                                src={item.image}
                                alt="cast-1"
                                className="img-fluid"
                                loading="lazy"
                              />}
                            </Col>
                            <Col className="col-8 block-description">
                              <h6 className="iq-title">
                                <Link to="/cast-detail">{item.name}</Link>
                              </h6>
                              <div className="video-time d-flex align-items-center my-2">
                                <small className="text-white">{item.role}</small>
                              </div>
                            </Col>
                          </Row>
                        </SwiperSlide>
                      );
                    }) : <span className="text-white">{showDetails?.show_cast}</span>}
                  </Swiper>
                </Tab.Pane>
                <Tab.Pane
                  className=" fade"
                  id="nav-crew"
                  eventKey="second"
                  role="tabpanel"
                  aria-labelledby="nav-crew-tab"
                >
                  <Swiper
                    slidesPerView={5}
                    loop={false}
                    modules={[Navigation]}
                    tag="ul"
                    className="position-relative swiper-card list-inline"
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                      },
                      576: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                      },
                      1025: {
                        slidesPerView: 5,
                        spaceBetween: 0,
                      },
                      1500: {
                        slidesPerView: 5,
                        spaceBetween: 0,
                      },
                    }}
                  >
                    {showDetails?.crew?.length > 0 ? showDetails?.crew?.map((item, index) => {
                      return (
                        <SwiperSlide key={index} as="li">
                          <Row className="cast-images m-0 align-items-center position-relative">
                            <div className="col-4 img-box p-0">
                              {item.image && <img
                                src={item.image}
                                alt="cast-1"
                                className="img-fluid"
                                loading="lazy"
                              />}
                            </div>
                            <div className="col-8 block-description">
                              <h6 className="iq-title">
                                <Link to="/cast-detail">{item.name}</Link>
                              </h6>
                              <div className="video-time d-flex align-items-center my-2">
                                <small className="text-white">{item.role}</small>
                              </div>
                            </div>
                          </Row>
                        </SwiperSlide>
                      )
                    }) : <>
                      {showDetails?.director && <span className="text-white"><Translate textKey={'director'} /> : {showDetails?.director},</span>}
                      {showDetails?.producer && <span className="text-white"> Producer : {showDetails?.producer},</span>}
                      {showDetails?.writer && <span className="text-white">Writer : {showDetails?.writer}</span>}
                    </>}
                  </Swiper>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>}
        </Container>
      </div>
      {showDetails?.single_video !== 1 && showDetails?.videos?.length === 1 && showDetails?.videos[0]?.season && <RelatedMovies title={<Translate textKey={'episodes'} />} movies={showDetails?.videos[0]?.videos} watchVideoHandler={watchVideoHandler} episode={true} episodeTitle={showDetails?.show_name} />}
      {showDetails?.single_video === 0 && showDetails?.videos?.length > 1 && showDetails?.videos[0]?.videos && <UpcomingMovies showDetails={showDetails} watchVideoHandler={watchVideoHandler} title={<Translate textKey={'episodes'} />} />}
      {similarVideos.length > 0 && <RelatedMovies title={<Translate textKey={'more_like_this'} />} movies={similarVideos} similarVideos={true} data />}
      <div className="my-4"></div>
    </Fragment>
  );
});

MovieDetail.displayName = "MovieDetail";
export default MovieDetail;
